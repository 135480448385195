import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import Button from 'components/base/Button'

type Props = {
  hideModal: () => void
}

const ModalNoHeader: FC<Props> = ({ hideModal, className, children }) => {
  return (
    <>
      {/* <div className={c`relative top-0 z-10 ${className}`}>
        <Button
          theme="gray"
          onClick={() => hideModal()}
          className="absolute -right-14 focus:ring-0 focus:ring-offset-0 shrink-0"
        >
          <XIcon className="text-gray-300 w-7 h-7" />
        </Button>
      </div> */}
      <div className={c`overflow-hidden rounded-lg ${className}`}>{children}</div>
    </>
  )
}

export default ModalNoHeader
