import { PlayIcon } from '@heroicons/react/outline'
import { useModal } from 'components/app/ModalDispatcher'
import Button from 'components/base/Button'
import Link from 'components/base/Link'
import YouTube from 'components/base/YouTube'
import { c, FC } from 'lib/component-utils'
import sellmonitorImage from '@/public/next/img/landing/product_card.png'
import sellscreenEnImage from '@/public/next/img/landing/en/product_card.png'
import sellscreenZhImage from '@/public/next/img/landing/zh/product_card.png'
import sellmonitorUzImage from '@/public/next/img/landing/uz/product_card.png'
import sellmonitorUzImageRu from '@/public/next/img/landing/uz/product_card_ru.png'
import Image, { StaticImageData } from 'next/image'
import { useIntl } from 'lib/intl-utils'
import { useUserContext } from 'lib/context'

import sokolovLogo from '@/public/next/img/landing/brand-logos/sokolov_black.svg'
import scarlettLogo from '@/public/next/img/landing/brand-logos/scarlett_black.svg'
import naturaSibericaLogo from '@/public/next/img/landing/brand-logos/natura_black.svg'
import pepsicoLogo from '@/public/next/img/landing/brand-logos/pepsico_black.svg'
import lorealLogo from '@/public/next/img/landing/brand-logos/loreal_black.svg'
import sebLogo from '@/public/next/img/landing/brand-logos/seb_black.svg'
import johnsonLogo from '@/public/next/img/landing/brand-logos/johnson_black.svg'
import nielsenLogo from '@/public/next/img/landing/brand-logos/nielsen_black.svg'
import bkLogo from '@/public/next/img/landing/brand-logos/bkcosmetic_black.svg'

import ModalNoHeader from 'components/base/ModalNoHeader'
import { useTrackGoal } from 'lib/metrics'

const contentByHost = {
  sellmonitor: {
    ru: {
      eyebrow: 'Сервис аналитики и продвижения товаров на Wildberries и Ozon',
      tagline: 'Подними свои продажи на маркетплейсах',
      description:
        'Sellmonitor поможет найти нишу, оптимизировать поставки, проанализировать стратегию конкурентов, повысить позиции своих товаров в выдаче и многое другое',
      image: sellmonitorImage,
      logos: [
        [
          { name: "L'Oreal", logo: lorealLogo },
          { name: 'Sokolov', logo: sokolovLogo },
          { name: 'Scarlett', logo: scarlettLogo },
        ],
        [
          //{ name: 'Jacobs', logo: jacobsLogo },
          { name: 'Natura Siberica', logo: naturaSibericaLogo },
          { name: 'Белорусская косметика', logo: bkLogo },
        ],
      ],
    },
  },
  uzum: {
    uz: {
      eyebrow: 'Uzum Market, Wildberries va Ozon bo‘yicha mahsulotlarni tahlil qilish xizmati',
      tagline: 'Marketpleyslardagi savdolaringizni oshiring',
      description:
        'Sellmonitor sizga bo‘sh nişani topish, yetkazib berishni optimallashtirish, raqiblarning strategiyasini tahlil qilish, mahsulotlaringizni qidiruv tizimlarida yuqori o‘rinlarga ko‘tarish va boshqa ko‘plab imkoniyatlarni taklif etadi',
      image: sellmonitorUzImage,
      logos: [
        [
          { name: "L'Oreal", logo: lorealLogo },
          { name: 'Sokolov', logo: sokolovLogo },
          { name: 'Scarlett', logo: scarlettLogo },
        ],
        [
          //{ name: 'Jacobs', logo: jacobsLogo },
          { name: 'Natura Siberica', logo: naturaSibericaLogo },
          { name: 'Belarus kosmetikasi', logo: bkLogo },
        ],
      ],
    },
    ru: {
      eyebrow: 'Сервис аналитики и продвижения товаров на Uzum Market, Wildberries и Ozon',
      tagline: 'Подними свои продажи на маркетплейсах',
      description:
        'Sellmonitor поможет найти нишу, оптимизировать поставки, проанализировать стратегию конкурентов, повысить позиции своих товаров в выдаче и многое другое',
      image: sellmonitorUzImageRu,
      logos: [
        [
          { name: "L'Oreal", logo: lorealLogo },
          { name: 'Sokolov', logo: sokolovLogo },
          { name: 'Scarlett', logo: scarlettLogo },
        ],
        [
          //{ name: 'Jacobs', logo: jacobsLogo },
          { name: 'Natura Siberica', logo: naturaSibericaLogo },
          { name: 'Белорусская косметика', logo: bkLogo },
        ],
      ],
    },
  },
  sellscreen: {
    en: {
      eyebrow: 'E-commerce analytics for Russian marketplaces',
      tagline: 'Boost your sales on popular marketplaces',
      description:
        "Sellscreen will help you find your niche, optimize logistics, analyze your competitors' strategy and much more",
      image: sellscreenEnImage,
      logos: [
        [
          { name: 'Groupe SEB', logo: sebLogo },
          { name: 'Scarlett', logo: scarlettLogo },
          { name: 'SC Johnson', logo: johnsonLogo },
        ],
        [
          { name: 'PepsiCo', logo: pepsicoLogo },
          { name: 'NielsenIQ', logo: nielsenLogo },
          { name: "L'Oreal", logo: lorealLogo },
        ],
      ],
    },
    zh: {
      eyebrow: '',
      tagline: '提高您在俄罗斯Ozon, WB, YM平台的上的销售额',
      description:
        'Sellscreen 将帮助您选品、成本优化，广告投放，SEO优化，定价，分析竞争对手的策略等。',
      image: sellscreenZhImage,
      logos: [
        [
          { name: 'Groupe SEB', logo: sebLogo },
          { name: 'Scarlett', logo: scarlettLogo },
          { name: 'SC Johnson', logo: johnsonLogo },
        ],
        [
          { name: 'PepsiCo', logo: pepsicoLogo },
          { name: 'NielsenIQ', logo: nielsenLogo },
          { name: "L'Oreal", logo: lorealLogo },
        ],
      ],
    },
  },
} as Partial<
  Record<
    string,
    Partial<
      Record<
        'ru' | 'en' | 'zh' | 'uz',
        {
          eyebrow: string
          tagline: string
          description: string
          image: StaticImageData
          logos: { name: string; logo: StaticImageData }[][]
        }
      >
    >
  >
>

const Hero: FC<{ forceContent?: keyof typeof contentByHost }> = ({ forceContent }) => {
  const {
    theme: { hostType },
  } = useUserContext()
  const { t, lang } = useIntl()
  const [showModal, hideModal] = useModal()
  const trackGoal = useTrackGoal()
  const content = contentByHost[forceContent ?? hostType]![lang]!

  return (
    <div className="isolate overflow-hidden relative bg-white">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_70%_at_center_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
        />
      </svg>
      <div className="px-6 pt-10 pb-12 mx-auto max-w-7xl sm:pb-24 lg:py-20 lg:px-8">
        <div className="lg:flex">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-10">
            {content.eyebrow && (
              <p className="text-sm font-medium text-gray-600 bg-gradient-to-tr from-blue-100 to-purple-100 rounded px-2 py-0.5 box-decoration-clone inline">
                {content.eyebrow}
              </p>
            )}
            <h1
              className={c`mt-4 text-4xl [@media_(min-width:480px)_and_(max-width:640px)]:text-5xl sm:text-6xl -ml-0.5 [font-variation-settings:'wdth'_150,'wght'_650] tracking-tight text-gray-900 ${
                lang === 'zh'
              } !leading-tight`}
            >
              {content.tagline}
            </h1>
            <p className="mt-6 text-lg text-gray-600">{content.description}</p>
            <div className="grid grid-rows-2 gap-y-4 gap-x-6 mt-12 w-fit sm:flex">
              <Button
                as={Link}
                href="/signup/"
                onClick={() => trackGoal('landing-clicked-register')}
                theme="accent"
                className="w-fit transition-shadow h-[42px] hover:!bg-lime-300 hover:!brightness-105 hover:[box-shadow:0_0_48px_5px_theme(colors.lime.300)]"
              >
                {t`landing.try_for_free`}
              </Button>
              {false && (
                <Button
                  theme="white"
                  className="w-full sm:w-fit"
                  onClick={() => {
                    trackGoal('landing-watched-video')
                    showModal(
                      <ModalNoHeader hideModal={hideModal}>
                        <YouTube
                          noBorder
                          noRounding
                          autoplay
                          id={'SO1VSE3fX4o'}
                          className="w-[calc(100vw-2rem)] lg:w-[80vw]"
                        />
                      </ModalNoHeader>
                    )
                  }}
                >
                  <PlayIcon className="flex-none w-6 h-6" />
                  <span className="ml-2.5">{t`landing.watch_video`}</span>
                </Button>
              )}
            </div>
          </div>
          <div className="flex mx-auto mt-16 max-w-2xl sm:mt-24 lg:ml-10 lg:-mt-4 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="flex-none max-w-3xl sm:max-w-5xl lg:max-w-none">
              <div className="block p-2 -m-2 rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <Image
                  src={content.image}
                  alt="App screenshot"
                  className="w-[56rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-36 text-center lg:mt-44">
          <p className="text-base font-medium text-slate-900">{t`landing.trusted_analytics`}</p>
          <ul
            role="list"
            className="flex gap-x-10 justify-center items-center mt-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
          >
            {content.logos.map((group, groupIndex) => (
              <li key={groupIndex}>
                <ul
                  role="list"
                  className="flex flex-col gap-y-8 items-center sm:flex-row sm:gap-x-12 sm:gap-y-0"
                >
                  {group.map((company) => (
                    <li key={company.name} className="flex justify-center w-32 h-20">
                      <Image src={company.logo} alt={company.name} unoptimized />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Hero
